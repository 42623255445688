@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.componentWebsiteContentPricing {

  .academy {
    background: var(--grad-multi);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: left;
    font-size: 30px;

    @include texts.font("bold");
  }

  .title {
    color: var(--white);
    font-size: 44px;
    text-align: center;

    @include texts.font("bold");
  }



  @media (max-width: 480px) {
    .title {
      font-size: 20px;
      width: 90%;
    }
  }
}