@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentWebsiteBlackPresentation {
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;

  &.center {
    align-items: center;

    .title {
      text-align: center;
    }

    .description {
      text-align: center;
    }
  }

  .title {
    font-size: 54px;
    width: 90%;

    @include texts.font("bold");
  }

  .description {
    font-size: 16px;
    width: 70%;
    z-index: 1;
  }

  .bigIcon {
    height: 190px;
    display: flex;
    justify-content: center;
    position: relative;

    .shadowTop {
      width: 62px;
      height: 62px;
      background: var(--white);
      filter: blur(60px);
      position: absolute;
    }

    .shadowBottom {
      width: 187px;
      height: 48px;
      background: var(--white);
      filter: blur(100px);
      position: absolute;
      bottom: 0;
    }

    .bigPrestashop {
      width: 290px;
      height: 290px;

      @include icons.ico("illus-black-prestashop");
    }

    .bigShopify {
      width: 290px;
      height: 290px;

      @include icons.ico("illus-black-shopify");
    }

    .bigWordpress {
      width: 290px;
      height: 290px;

      @include icons.ico("illus-black-wordpress");
    }

    .bigWix {
      width: 290px;
      height: 290px;

      @include icons.ico("illus-black-wix");
    }
  }

  .buttons {
    display: flex;
    gap: 15px;
    position: relative;

    .start {
      position: relative;
      border-radius: 20px;
      border-top: 2px solid var(--web-border-blue);
      background: var(--web-grad-blue);
      font-size: 16px;
      color: var(--white);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 32px;
      height: fit-content;
      cursor: pointer;

      &:hover {
        background: var(--web-blue-hover);

        .icon {
          transform: translateX(8px);
        }
      }

      &.white {
        background: var(--white);
        border: unset;
        color: var(--black);

        .icon {
          filter: var(--icon-black) !important;
        }
        a {
          color: unset;
        }
      }

      .shadow {
        background: var(--web-blue);
        filter: blur(100px);
        width: 168px;
        height: 168px;
        position: absolute;
        pointer-events: none;
      }

      .content {
        display: flex;
        gap: 15px;
        position: relative;
        align-items: center;

        .icon {
          height: 16px;
          width: 16px;
          filter: var(--icon-white);
          transition: transform 0.1s ease-in-out;

          @include icons.ico("black-right-arrow");

          &.prestashop {
            height: 22px;
            width: 22px;
            filter: unset !important;

            @include icons.ico("colored-prestashop");
          }
        }
      }
    }

    .people {
      align-items: center;
      background: var(--web-background-steps);
      border: 1px solid var(--web-border-white);
      font-size: 14px;
      color: var(--white);
      padding: 13px 18px;
      display: flex;
      gap: 15px;
      width: 366px;
      border-radius: 15px;

      .icon {
        height: 18px;
        width: 18px;
        flex: 0 0 18px;
        filter: var(--icon-blue-black-web);

        @include icons.ico("black-star-fod");
      }
    }
  }

  .whiteShadow {
    width: 239px;
    height: 124px;
    position: absolute;
    background: rgba(255, 255, 255, 0.20);
    filter: blur(100px);
    top: -77px;
    left: calc(50% - 120px)
  }

  @media screen and (max-width: 930px) {
    .title {
      font-size: 36px;
      text-align: center;
      width: 100%;
    }

    .description {
      width: 100%;
      text-align: center;
    }

    .buttons {
      flex-direction: column;
      align-items: center;

      .start {
        width: calc(100% - 64px);
      }

      .people {
        position: relative;
        width: calc(100% - 36px);
      }
    }
  }
}