@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentWebsiteReviews {
  .title {
    font-size: 44px;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;

    @include texts.font("bold");
  }

  .reviews {
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
  }
}