@use "../../../../../../sedestral-interface-modules/sedestral-interface-sass/icons/icons";
@use "../../../../../../sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.componentWebsiteBlogArticle {
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;

  &:hover {
    .content {
      .title {
        text-decoration: underline;
      }
    }
  }

  .image {
    width: 100%;
    height: 276px;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 5px;
    border: 1px solid var(--grey-sur);
    cursor: pointer;
    background: var(--grey-sur);
    color: transparent;

    &.empty {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 120px;
        opacity: 0.2;
      }
    }

    img {
      width: 100%;
    }
  }

  .content {
    margin-bottom: 10px;

    .title {
      font-size: 22px;
      margin-top: 10px;
      margin-bottom: 10px;
      color: var(--black);

      @include texts.font("bold");
    }

    .text {
      margin: 0;
      font-size: 15px;
      color: var(--grey-text-dark);
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      @include texts.font("medium");
    }
  }

  .details {
    font-size: 12px;
    color: var(--grey-text);
  }

  .bottom {
    margin-top: 20px;
  }

  @media screen and (max-width: 1010px) {
    .image {
      height: 220px;
    }
  }

  @media screen and (max-width: 850px) {
    .image {
      height: 235px;
    }
  }
}