import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "../website-content.scss";
import * as r from "../statistics/website-content-statistics.scss";
import {
    WebsiteIssuesComponent
} from "../../issues/WebsiteIssuesComponent";
import {Resources} from "../../../../../../../resources/Resources";
import {WebsiteColumnsGridWhiteComponent} from "../../columns/grid/white/WebsiteColumnsGridWhiteComponent";
import {NetworkVendor} from "../../../../../../../network/NetworkVendor";
import {WebsiteReviewsComponent} from "../../reviews/WebsiteReviewsComponent";
import {CustomerPopup} from "../../../../../../panel/components/components/customer/popup/types/CustomerPopup";
import {WebsiteBlackPresentationComponent} from "../../../black/presentation/WebsiteBlackPresentationComponent";
import {WebsiteBlackStepsComponent} from "../../../black/steps/WebsiteBlackStepsComponent";
import {WebsiteBlackFlowComponent} from "../../../black/flow/WebsiteBlackFlowComponent";
import {WebsiteBlackPlatformComponent} from "../../../black/platform/WebsiteBlackPlatformComponent";
import {WebsiteBlackWordsComponent} from "../../../black/words/WebsiteBlackWordsComponent";

export class WebsiteContentBlogShopifyComponent extends Component {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteContent} ${r.componentWebsiteContentStatistics}">
                <div class="${s.content}">
                    <div class="${s.container}">
                        ${this.draw(new WebsiteBlackPresentationComponent({
                            startText: Resources.t("words.beginFree"),
                            center: true,
                            title: Resources.t("words.shopifyModuleTitle"),
                            description: Resources.t("words.shopifyModuleDescription"),
                            partnerIcon: "shopify",
                            secondButton: false
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteBlackStepsComponent({
                            title: Resources.t("words.generationSteps"),
                            icon: "seo",
                            center: true,
                            contents: [
                                {
                                    name: Resources.t("words.keyword"),
                                    type: "video",
                                    path: NetworkVendor.image("blog-e.mp4"),
                                    duration: 14000
                                },
                                {
                                    name: Resources.t("words.instructions"),
                                    type: "video",
                                    path: NetworkVendor.image("blog-f.mp4"),
                                    duration: 8000
                                },
                                {
                                    name: Resources.t("words.plan"),
                                    type: "video",
                                    path: NetworkVendor.image("blog-g.mp4"),
                                    duration: 21000
                                },
                                {
                                    name: Resources.t("words.writing"),
                                    type: "video",
                                    path: NetworkVendor.image("blog-h.mp4"),
                                    duration: 15000
                                },
                            ]
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteReviewsComponent(Resources.t("words.whatShopifyClientsSay"), NetworkVendor.reviews()))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteColumnsGridWhiteComponent({
                            title: Resources.t("words.searchEngineContent"),
                            color: "black",
                            details: [{
                                icon: "black-lineal-read",
                                title: Resources.t("words.fleschReadingEase"),
                                subtitle: Resources.t("words.fleschReadingEaseSubtitle")
                            }, {
                                icon: "black-lineal-keywords",
                                title: Resources.t("words.keywords"),
                                subtitle: Resources.t("words.keywordsSubtitle")
                            }, {
                                icon: "black-lineal-words",
                                title: Resources.t("words.transitionWords"),
                                subtitle: Resources.t("words.transitionWordsSubtitle")
                            }, {
                                icon: "black-lineal-code",
                                title: Resources.t("words.htmlTagging"),
                                subtitle: Resources.t("words.htmlTaggingSubtitle")
                            }, {
                                icon: "black-lineal-voice",
                                title: Resources.t("words.passiveVoice"),
                                subtitle: Resources.t("words.passiveVoiceSubtitle")
                            }, {
                                icon: "black-lineal-seo",
                                title: Resources.t("words.seoTools"),
                                subtitle: Resources.t("words.seoToolsSubtitle")
                            }]
                        }))}
                    </div>
                </div>
                <div class="${s.white}">
                    <div class="${s.content}">
                        <div class="${s.container}">
                            ${this.draw(new WebsiteBlackFlowComponent({
                            title: Resources.t("words.bestSeoAlly"), 
                            bigTitle: Resources.t("words.shopifySeoApp"),
                            image: NetworkVendor.image("blog-n.webp"),
                            texts: {
                                canCollapse: true,
                                entries: [
                                    {
                                        title: Resources.t("words.optimizeExistingSeoShopify"),
                                        description: Resources.t("words.optimizeExistingSeoDescriptionShopify"),
                                        icon: "aiStars",
                                        iconColor: "colorPurple"
                                    },
                                    {
                                        title: Resources.t("words.createEngagingContent"),
                                        description: Resources.t("words.createEngagingContentDescription"),
                                        icon: "aiStars",
                                        iconColor: "colorPurple"
                                    },
                                    {
                                        title: Resources.t("words.competitorAnalysis"),
                                        description: Resources.t("words.competitorAnalysisDescription"),
                                        icon: "aiStars",
                                        iconColor: "colorPurple"
                                    },
                                    {
                                        title: Resources.t("words.autopilot"),
                                        description: Resources.t("words.delegateContentCreationDescription"),
                                        icon: "aiStars",
                                        iconColor: "colorPurple"
                                    }
                                ]
                            }
                            }))}
                        </div>
                    </div>
                </div>
                <div class="${s.content}">
                    <div class="${s.container}">
                        ${this.draw(new WebsiteBlackPlatformComponent({
                            title: Resources.t("words.ourPlatform"),
                            description: Resources.t("words.shopifySeoModuleDescription"),
                            grid: {
                                lines: [{
                                    entries: [{
                                        title: Resources.t("words.automaticPilot"),
                                        description: Resources.t("words.automaticPilotDescription"),
                                        type: "icon",
                                        icon: "autopilot",
                                        size: "sizeMidColumn",
                                        color: "colorBlue",
                                        shadowPosition: "shadowMid"
                                    }, {
                                        title: Resources.t("words.workInAnyLanguage"),
                                        description: Resources.t("words.workInAnyLanguageDescription"),
                                        type: "icon",
                                        icon: "languages",
                                        size: "sizeMidColumn",
                                        color: "colorYellow",
                                        shadowPosition: "shadowMid"
                                    }]
                                }]
                            }
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteIssuesComponent({
                                    theme: "black",
                                    "questions": [
                                        {
                                            "question": Resources.t("words.doesGooglePenalizeAiContent"),
                                            "response": Resources.t("words.googleDoesNotPenalizeAiContent")
                                        },
                                        {
                                            "question": Resources.t("words.seoOptimizationQuestion"),
                                            "response": Resources.t("words.seoOptimizationResponse")
                                        },
                                        {
                                            "question": Resources.t("words.importContentQuestion"),
                                            "response": Resources.t("words.importContentResponse")
                                        },
                                        {
                                            "question": Resources.t("words.contentCustomizationQuestion"),
                                            "response": Resources.t("words.contentCustomizationResponse")
                                        }
                                    ]
                                }
                        ))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteBlackWordsComponent())}
                    </div>
                </div>
            </div>
        `;
    }

    commit() {
        CustomerPopup.blog();
        super.commit();
    }

}
