import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./website-blog-article.scss";
import {
    IMG_EMPTY
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/SedestralUtils";
import {IWebsiteBlogArticle} from "./types/IWebsiteBlogArticle";
import {
    filterXSS
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/FilterXSS";
import {Resources} from "../../../../../../resources/Resources";
import {
    dateFormatMeta
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/DateFormatMeta";
import {
    dateFormatArticle
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/DateFormatArticle";

export class WebsiteBlogArticleComponent extends Component {

    public article: IWebsiteBlogArticle;

    public imageContainer: Component;

    constructor(article: IWebsiteBlogArticle) {
        super();
        this.article = article;

        //language=HTML
        this.template = `
            <article itemprop="blogPosts" itemscope itemtype="https://schema.org/BlogPosting"
                     class="${s.componentWebsiteBlogArticle}">
                <meta itemprop="url" content="${this.article.url}">
                <meta itemprop="inLanguage" content="fr">
                <meta itemprop="articleSection" content="${filterXSS(this.article.title)}">
                <div class="${s.image}"></div>
                <div class="${s.content}">
                    <h2 itemprop="headline" class="${s.title}">
                        ${filterXSS(this.article.title)}
                    </h2>
                    <p itemprop="description" class="${s.text}">
                        ${filterXSS(this.article.snippet)}
                    </p>
                </div>

                <div class="${s.details}">
                    ${dateFormatArticle(new Date(this.article.time))} |
                    ${Math.round(this.article.readingSeconds / 60)} ${Resources.t("words.timeToRead")}
                </div>
                ${this.article.keywords.length > 0 ? `<meta itemprop="keywords" content="${filterXSS(this.article.keywords.join(", "))}">` : ``}
                <meta itemprop="datePublished" content="${dateFormatMeta(new Date(this.article.time))}">
                ${this.article.lastEditTime > 0 ? `<meta itemprop="dateModified" content="${dateFormatMeta(new Date(this.article.lastEditTime))}">` : ``}
            </article>
        `;
    }

    commit() {
        this.imageContainer = this.el(s.image);
        this.renderImage();
        super.commit();
    }

    renderImage() {
        //language=HTML
        this.imageContainer.append(`
            <img class="lazyload" itemprop="image" alt="${this.article.title}"
                 src="${IMG_EMPTY}"
                 data-src="https://marketing-assets.surferseo.art/wf-cdn/62666115cfab453aacbd513c/67584a0e2d788b53571b9762_Academy%2010%20-%20Cover-p-1080.png"
                 data-sizes="auto"
                 width="auto"
                 height="auto"
            />
        `);

        //language=HTML
        this.imageContainer.append(`
            <noscript>
                <img itemprop="image" src="https://marketing-assets.surferseo.art/wf-cdn/62666115cfab453aacbd513c/67584a0e2d788b53571b9762_Academy%2010%20-%20Cover-p-1080.png" alt="${this.article.title}" width="auto"
                     height="auto"/>
            </noscript>
        `);

    }
}