import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "../website-content.scss";
import * as i from "./../../columns/steps/website-columns-steps.scss";
import {WebsiteProductComponent} from "../../product/WebsiteProductComponent";
import {WebsitePresentationVerticalComponent} from "../../presentation/vertical/WebsitePresentationVerticalComponent";
import {WebsiteColumnsStepsComponent} from "../../columns/steps/WebsiteColumnsStepsComponent";
import {WebsiteSliderLeftComponent} from "../../slider/left/WebsiteSliderLeftComponent";
import {ProductType} from "../../../../../../../models/product/ProductType";
import {Network} from "../../../../../../../network/Network";
import {
    WebsiteIssuesComponent
} from "../../issues/WebsiteIssuesComponent";
import {Resources} from "../../../../../../../resources/Resources";
import {WebsiteColumnsGridWhiteComponent} from "../../columns/grid/white/WebsiteColumnsGridWhiteComponent";
import {WebsitePresentationLightComponent} from "../../presentation/light/WebsitePresentationLightComponent";
import {NetworkVendor} from "../../../../../../../network/NetworkVendor";
import {WebsiteReviewsComponent} from "../../reviews/WebsiteReviewsComponent";
import {CustomerPopup} from "../../../../../../panel/components/components/customer/popup/types/CustomerPopup";

export class WebsiteContentBlogComponent extends Component {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteContent}">
                <div class="${s.content}">
                    <div class="${s.container}">
                        ${this.draw(new WebsiteProductComponent({
                            title: Resources.t("words.letAIGenerateArticles"),
                            text: Resources.t("words.smartBlogDescription"),
                            productType: ProductType.BLOG,
                            url: NetworkVendor.image("blog-a.webp"),
                            buttonText: Resources.t("words.generatethreeFreeContents"),
                            buttonUrl: Network.router.pages.getPanelUrl('signup'),
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsitePresentationVerticalComponent({
                            title: Resources.t("words.seeSmartBlogInAction"),
                            image: NetworkVendor.image("blog-video-a.mp4"),
                            color: "black",
                            size: "tall",
                            border: "border",
                            shadow: "shadow"
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteColumnsStepsComponent({
                            title: Resources.t("words.howItWorks"),
                            grid: [{
                                name: Resources.t("words.customizeConfig"),
                                description: Resources.t("words.customizeDescription"),
                                icon: i.pensil
                            }, {
                                name: Resources.t("words.explainTitle"),
                                description: Resources.t("words.explainDescription"),
                                icon: i.computer
                            }, {
                                name: Resources.t("words.publishArticleTitle"),
                                description: Resources.t("words.publishArticleDescription"),
                                icon: i.letter
                            }
                            ]
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsitePresentationVerticalComponent({
                            title: Resources.t("words.connectExistingBlog"),
                            description: Resources.t("words.chooseCmsPlatform"),
                            image: NetworkVendor.illus("illus-cms.svg"),
                            color: "black",
                            size: "intermediate"
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsitePresentationVerticalComponent({
                            title: Resources.t("words.customizeYourBlog"),
                            description: Resources.t("words.customizeBlogDescription"),
                            image: NetworkVendor.image("blog-d.webp"),
                            color: "multi",
                            size: "tall"
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteSliderLeftComponent(Resources.t("words.iaArticleWriting"),
                                [{
                                    icon: "draft",
                                    title: Resources.t("words.instructionsTitle"),
                                    desc: Resources.t("words.instructionsDescription"),
                                    image: NetworkVendor.image("blog-h-1.webp")
                                }, {
                                    icon: "paragraph",
                                    title: Resources.t("words.proposalsTitle"),
                                    desc: Resources.t("words.proposalsDescription"),
                                    image: NetworkVendor.image("blog-h-2.webp")
                                }, {
                                    icon: "section",
                                    title: Resources.t("words.planTitle"),
                                    desc: Resources.t("words.planDescription"),
                                    image: NetworkVendor.image("blog-h-3.webp")
                                }, {
                                    icon: "pensil",
                                    title: Resources.t("words.writingTitle"),
                                    desc: Resources.t("words.writingDescription"),
                                    image: NetworkVendor.image("blog-h-4.webp")
                                }]
                        ))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsitePresentationLightComponent({
                            title: Resources.t("words.seoOptimizedBlog"),
                            subtitle: Resources.t("words.seoOptimizedBlogSubtitle"),
                            illus: "illus-seo",
                            size: "medium",
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteColumnsGridWhiteComponent({
                            title: Resources.t("words.otherFeatures"),
                            color: "black",
                            details: [{
                                icon: "black-lineal-domains",
                                title: Resources.t("words.domainNameTitle"),
                                subtitle: Resources.t("words.domainNameSubtitle")
                            }, {
                                icon: "black-export",
                                title: Resources.t("words.exportArticlesTitle"),
                                subtitle: Resources.t("words.exportArticlesSubtitle")
                            }, {
                                icon: "black-lineal-delay",
                                title: Resources.t("words.scheduleArticlesTitle"),
                                subtitle: Resources.t("words.scheduleArticlesSubtitle")
                            }]
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteReviewsComponent(Resources.t("words.whatOurClientsSay"), NetworkVendor.reviews()))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteIssuesComponent({
                            questions: [{
                                "question": Resources.t("words.doesGooglePenalizeAiContent"),
                                "response": Resources.t("words.googleDoesNotPenalizeAiContent")
                            }, ...Resources.t("words.questions.smartblog")]
                        }))}
                    </div>
                </div>
        `;
    }

    commit() {
        CustomerPopup.blog();
        super.commit();
    }
}