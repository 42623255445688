import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./website-black-presentation.scss";
import {IWebsiteBlackPresentation} from "./types/IWebsiteBlackPresentation";
import {Network} from "../../../../../../network/Network";
import {Resources} from "../../../../../../resources/Resources";

export class WebsiteBlackPresentationComponent extends Component {

    public p: IWebsiteBlackPresentation;

    public buttons: Component;

    constructor(p: IWebsiteBlackPresentation) {
        super();
        this.p = p;

        let bigIconClass = undefined;
        if (p.partnerIcon) {
            switch (p.partnerIcon) {
                case "prestashop":
                    bigIconClass = s.bigPrestashop;
                    break;
                case "wordpress":
                    bigIconClass = s.bigWordpress;
                    break;
                case "shopify":
                    bigIconClass = s.bigShopify;
                    break;
                case "wix":
                    bigIconClass = s.bigWix;
                    break;
            }
        }

        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteBlackPresentation} ${p.center ? s.center : ``}">
                <div class="${s.whiteShadow}"></div>
                <div class="${s.title}">
                    ${p.title}
                </div>
                <div class="${s.description}">
                    ${p.description}
                </div>
                ${bigIconClass ? `
                <div class="${s.bigIcon}">
                    <div class="${s.shadowTop}"></div>
                    <div class="${s.shadowBottom}"></div>
                   <div class="${bigIconClass}"></div>
                </div>
                ` : ``}
                <div class="${s.buttons}">
                    <a href="${p.firstButtonLink ?? Network.router.pages.getPanelUrl('signup')}" target="_blank" class="${s.start}">
                        <div class="${s.shadow}"></div>
                        <div class="${s.content}">
                            ${p.startText}
                            <div class="${s.icon}"></div>
                        </div>
                    </a>
                    ${p.secondButton ? `
                    <a href="https://prestashop.strl.io/my-blog" target="_blank" class="${s.start} ${s.white}">
                       <div class="${s.content}">
                            <div class="${s.icon} ${s.prestashop}"></div>
                            ${Resources.t("words.viewDemoBlog")}
                            <div class="${s.icon}"></div>
                        </div>
                    </a>
                    ` : ``}
                </div>
            </div>
        `;
    }

    commit() {
        this.buttons = this.el(s.buttons);
        if (this.p.people) {
            //language=HTML
            this.buttons.append(`
                <div class="${s.people}">
                    <div class="${s.icon}"></div>
                    ${Resources.t("words.improveRankingMessage")}
                </div>
            `);
        }

        super.commit();
    }
}